import { BRAND } from '../../../constants'
import { getFormattedISODate } from '../../../controllers/helpers'

const WrittenBy = ({ author, locale, listicleDate }) => {
  
  const { firstname, lastname } = author || { firstname: 'Luke', lastname: 'Bull' }
  return (
  <div className='written-by'>
    <p>Written by {firstname} {lastname} - {getFormattedISODate(listicleDate, locale)}</p>
    <style jsx>{`
      .written-by {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-width: 800px;
        width: 100%;
        margin: 0px 6px;
      }
      .author {
        width: 30px;
        height: 24px;
        object-fit: cover;
        border-radius: 20px;
        margin: 6px;
        position: relative;
        overflow: hidden;
      }

      @media screen and (max-width: ${BRAND.media.small}px) {
        .written-by {
          flex-direction: column;
        }
      }
    `}</style>
  </div>
)}

export default WrittenBy
